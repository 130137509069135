
.banner-outer {
    background: #46652E;
    margin-bottom: 80px;
    .caravancampingsales & {
        background: #46652E; 
    }

    .boatsales & {
        background: #389CD4;
        
        .new-badge img {
            width: 40px;
            height: auto;
        }

        .md-banner-section .mdbanner-list-item li {
            background-image: url(https://resource.csnstatic.com/prospect/assets/tick-icon-bs.svg);
        }

        .md-banner-section {
            .md-link-button a {
                color: #00487E;
            }
            
            .md-banner-logo > img {
                filter: brightness(0) invert(1);
            }
        }

        @media (max-width: 1200px) {
            .md-banner-section .mobile-img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

.p-l-0 {
    padding-left: 0 !important;
}

.mdbanner-list-item {
    display: flex;
    color: #fff;
    list-style: none;

    li {
        padding: 0 15px;
    }
}

.md-banner-section {
    position: relative;

    .new-badge {
        position: absolute;
        top: -12px;

        img {
            width: 23px;
            height: 27px;
        }
    }

    .md-btn-section {
        display: flex;

        > div {
            align-self: flex-end;
            margin: 20px 0px;
        }
    }

    .md-banner-para {
        color: #fff;
        padding: 30px 0 0;
        line-height: 34px;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .md-banner-logo {
        max-width: 65%;
        padding: 15px 0 15px;
        display: flex;
        justify-content: center;
        > img {
            width: 100%;
        }
    }

    .md-link-button {
        display: flex;
        justify-content: center;

        a {
            background: #fff;
            border-radius: 50px !important;
            font-size: 14px !important;
            font-weight: 600;
            line-height: 20px !important;
            border: 0 none !important;
            padding: 9px 20px;
            cursor: pointer;
            width: 200px;
            text-align: center;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .mdbanner-list-item {
        display: flex;
        color: #fff;
        list-style: none;
        padding: 0 0 25px;
        margin: 0;

        li {
            padding: 0 15px 0 25px;
            background: url(https://resource.csnstatic.com/prospect/assets/tick-icon-darkbg.svg) no-repeat;
        }
    }
}

@media (max-width: 991px) {
    .banner-outer {
        margin: 0 15px;
        border-radius: 8px;
    }

    .md-banner-section .mobile-img {
        margin-top: 0;
        height: 200px !important;
    }

    .md-banner-para {
        font-size: 16px;
    }

    .md-banner-section .mdbanner-list-item {
        font-size: 14px;
        padding-bottom: 15px !important;
    }

    .mobile-img {
        width: 100%;
    }

    .md-banner-section .md-link-button {
        justify-content: flex-start;

        a {
            background: transparent !important;
            font-size: 14px !important;
            color: #fff !important;
            font-weight: 600;
            margin-bottom: 15px;
            padding: 0 !important;

            img {
                margin-left: 5px;
            }
        }
    }
}

@media (max-width: 1200px) {
    .md-banner-section .mobile-img {
        width: 100%;
    }
}

.caravancampingsales {
    .banner-outer {
        margin-bottom: 0px;
    }
}
